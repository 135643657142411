<template>
  <div class="DjMixStartAndroid">
    <h1> This is from Android Dj's Starting mix page   </h1>
  </div>
</template>
<script>
  export default{
    name: 'DjMixStartAndroidPage,'
  };
</script>
